import React from "react";
import MediaComponentWrapper, {
  MediaComponentStructure
} from "../../ui/pages/project_detailed_page/info_components/media_component/MediaComponentWrapper";
import ImageComponent
  from "../../ui/pages/project_detailed_page/info_components/media_component/image_component/ImageComponent";
import TextComponent, {
  TextComponentType
} from "../../ui/pages/project_detailed_page/info_components/text_component/TextComponent";
import Credits from "../../ui/pages/project_detailed_page/info_components/credits/Credits";
import VimeoPlayer, {
  VideoPauseOnHideConfig
} from "../../ui/pages/project_detailed_page/info_components/media_component/vimeo_player/VimeoPlayer";

const OctripusPage: React.FC = () => {
  return(
    <>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'<p>По результатом исследований, глубинных интервью и конкурентного анализа для Octripus был выбран формат, близкий к социальной сети. Таким образом авторы и потребители контента концентрируются на сути создаваемого, не отвлекаясь на процесс. Для путешественника, приобретающего персональный гайд перед поездкой в неизведанную страну, важно получить продукт в удобной и доступной форме. А для автора, создающего и продвигающего такой контент, важно иметь возможность создавать его достаточно быстро, и при этом презентабельно и ярко.</p>' +
          '<p>Визуал — светлая тема, сочетание контрастных брендового зеленого, чёрного и серого, современный скруглённый шрифт. Элементы интерфейса скруглённые, «плавные». Общение с пользователем — дружелюбное и неформальное.</p>' +
          '<p>В рамках проекта предполагается разработка веб-версии и нативного мобильного приложения. В первую очередь разрабатывается веб. В дизайне принят mobile first, что не только традиционно упрощает работу дизайнеров и разработчиков, но и делает пользовательский опыт большей части аудитории, заходящей с мобильных устройств, идеальным.</p>'}
        engText={'<p>Based on research, in-depth interviews, and competitor analysis, we chose a format resembling a social network for Octripus. This approach helps both creators and users focus on the content without being distracted by the process. For travelers purchasing a personalized guide before visiting a new country, it’s essential to receive the content in a convenient and accessible format. Meanwhile, creators need tools that allow them to produce content quickly while maintaining a polished and visually appealing presentation.</p>' +
          '<p>The design features a light theme with a combination of contrasting brand colors: green, black, and gray, paired with a modern, rounded font. Interface elements are rounded and smooth, fostering a friendly and informal interaction style with users.</p>' +
          '<p>The project includes the development of a web version and a native mobile application. The web version is prioritized for development. The design approach follows a mobile-first strategy, which not only streamlines the workflow for designers and developers but also ensures an optimal user experience for the majority of the audience accessing the platform from mobile devices.</p>'
        }
      />
      <MediaComponentWrapper
        structure={MediaComponentStructure.DOUBLE_MEDIA}
        thirdChild={<ImageComponent src={'https://i.imgur.com/mGicu1D.jpeg'} />}
      >
        <ImageComponent src={'https://i.imgur.com/KBdzFYG.jpeg'}/>
        <ImageComponent src={'https://i.imgur.com/tUTR9a5.jpeg'}/>
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Интерфейс разделён на табы — лента, поиск, сохранёнки и профиль. Для проекта важно не изобретать велосипед, а сделать так, чтоб пользователь понимал, куда нажимать. Интерфейс лаконичный, имеет характер, но узнаваемый.'}
        engText={'The interface is divided into tabs: feed, search, saved items, and profile. The goal is not to reinvent the wheel but to create an intuitive experience where users immediately understand where to click. The design is minimalist yet distinctive and recognizable.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/IE0Bw5V.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/54dgMv7.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Для создателей контента важна персонализация профиля, поэтому уделено внимание традиционным элементам профилей: баннерная шапка, фотографии профиля, прикрепление ссылок, активация CTA-кнопки для запроса на создание индивидуального контента.'}
        engText={'Personalization of profiles is crucial for content creators, so traditional profile elements have been given special attention: a banner header, profile pictures, link attachments, and the activation of a CTA button for requesting custom content creation.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/xRf8D4b.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Процесс редактирования профиля — он просто удобен. Больше ничего и не требуется.'}
        engText={'The profile editing process is straightforward and convenient. Nothing more is needed.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/KGyzQH1.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/3V5Tazf.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'При проектировании UX большое внимание также уделено и юзерфлоу, затрагивающим безопасность.'}
        engText={'In UX design, significant attention is also given to user flows related to security.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/8QlwiLl.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/G9j2p4n.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Сейчас можно создавать два типа контента — Гайды и Коллекции (комплекты из множества Гайдов). Просматривая ленту, пользователь сразу может оценить объём и содержание гайда, и сохранить «на потом».'}
        engText={'Currently, two types of content can be created: Guides and Collections (sets consisting of multiple Guides). While browsing the feed, users can quickly assess the scope and content of a Guide and save it for later.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/yZcylox.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/CwC3Vma.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Вкладка с сохранёнками — личная «библиотека» пользователя. Она позволяет собирать понравившийся контент, а также хранит приобретённый и тот, к которому есть индивидуальный доступ по ссылке.'}
        engText={'The Saved tab serves as the user\'s personal "library." It allows them to collect favorite content, as well as store purchased content and items with individual access via a link.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/miBD7KG.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/Til3xPF.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Каждый гайд сопровождается картой, на которой отмечены точки. Можно читать гайд целиком или просматривать места списком, с открытой картой.'}
        engText={'Each Guide is accompanied by a map with marked points. Users can read the Guide in full or view the locations as a list with the map open.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/wDFsbON.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/Zi5sTqq.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/eInHbM8.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/4LCzHuZ.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper
        structure={MediaComponentStructure.WITH_TEXT}
        engText={'The process of ordering custom content offers a vast area for research and analysis. It is envisioned that users will order content by filling out a brief and simple form, followed by direct communication with the creator.'}
        rusText={'Процесс заказа индивидуального контента – большое поле для исследований и анализа. Предполагается, что пользователи будут заказывать контент, заполняя краткий простой бриф, а потом связываясь с автором.'}
      >
        <VimeoPlayer videoId={'1050335502'} loop={true} pauseOnHideConfig={VideoPauseOnHideConfig.FIRST_SCROLL} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.H3_SHORT}
        rusText={'Редактор создания гайда и коллекции сделан по принципу модулей-блоков: заполнив несколько фиксированных обязательных, автор может создавать в нужном порядке и количестве остальные блоки. При этом они уже задизайнены и оптимизированы — с одной стороны, конструктор гибкий, а с другой стороны, на выходе всегда красивый контент.'}
        engText={'The guide and collection editor is block-based. After filling out a few required fields, creators can add additional blocks in any order and quantity. These blocks are pre-designed and optimized, striking a balance between flexibility and consistent visual quality.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/HIpF2Bh.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'В Octripus я создал полноценный, удобный для всех устройств конструктор-редактор. Для десктопа были продуманы шорткаты, с помощью которых можно мгновенно оперировать с блоками, а для мобильных — адаптивная панель инструментов, жесты, драг-н-дроп. Инструментов достаточно много для того, чтоб создавать контент для любой вкус. И, конечно, разрабатываются AI-решения.'}
        engText={'In Octripus, I created a fully functional, device-friendly editor-builder. For desktop, shortcuts were designed to instantly manage blocks, while for mobile, there is an adaptive toolbar, gestures, and drag-and-drop functionality. There are plenty of tools to create content for any taste. And, of course, AI solutions are being developed.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/FHYV9TA.jpeg'} />
      </MediaComponentWrapper>
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/5dOsQbr.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'В процессе формирования автор в конструкторе видит гайд так, как он будет выглядеть в итоге при просмотре. Гайд можно сохранить как черновик. Автосохранение и другие UX-сценарии тоже отработаны.'}
        engText={'During the creation process, the author sees the Guide as it will appear when viewed. The Guide can be saved as a draft. Auto-save and other UX scenarios have also been implemented.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/j2sitVb.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_SHORT}
        rusText={'Предполагается использование множества инструментов, которые облегчат перенос ранее созданного контента на платформу.'}
        engText={'A variety of tools are planned to simplify the transfer of previously created content to the platform.'}
      />
      <MediaComponentWrapper structure={MediaComponentStructure.STANDARD}>
        <ImageComponent src={'https://i.imgur.com/8M5a00r.jpeg'} />
      </MediaComponentWrapper>
      <TextComponent
        type={TextComponentType.ACCENT_TEXT_LONG}
        rusText={'В рамках работы над продуктом проводится множество исследований, которые позволят выявить идеальный путь для автора тревел-контента к стабильному заработку на своей экспертности, а для путешественника, номада или случайного туриста — к гарантированному получению лучших впечатлений от исследования новой локации.'}
        engText={'Ongoing research is focused on identifying the ideal path for travel content creators to achieve stable income through their expertise and for travelers, nomads, or casual tourists to have unforgettable experiences in new locations.'}
      />
      <Credits lines={[
        {
          rusName: 'Product and UI/UX design:',
          rusVal: 'Глеб Коссов',
          engName: 'Lead designer (UI/UX, graphic):',
          engVal: 'Gleb Kossov'
        },
        {
          rusName: 'Brand:',
          rusVal: 'Toro Manafov',
          engName: 'Brand:',
          engVal: 'Toro Manafov'
        },
        {
          engName: 'Licensed mockups:',
          engVal: 'LS graphics',
          link: 'https://www.ls.graphics/'
        }
      ]}/>
    </>
  )
}

export default OctripusPage
