import {ProjectInfo} from "../types/ProjectInfoTypes";
import {LinkButtonType} from "../ui/shared_components/link_button/LinkButton";
import {ToolTagType} from "../ui/shared_components/tool_tag/ToolTag";
import CentrofinansPage from "./project_pages/CentrofinansPage";
import RKP_Page from "./project_pages/RKP_Page";
import HPT_Page from "./project_pages/HPT_Page";
import VirtonexPage from "./project_pages/VirtonexPage";
import YourmealPage from "./project_pages/YourmealPage";
import SignaxPage from "./project_pages/SignaxPage";
import OctripusPage from "./project_pages//OctripusPages";

const cf_preview = 'https://i.imgur.com/URKoNas.png'
const hookah_preview = 'https://i.imgur.com/oqhtWih.png'
const signax_preview = 'https://i.imgur.com/v931uJf.png'
const yourmeal_preview = 'https://i.imgur.com/R69RpR6.png'
const virtonex_preview = 'https://i.imgur.com/MB8EurI.png'
const rkp_preview = 'https://i.imgur.com/DSh0LOP.png'
const octripus_preview = 'https://i.imgur.com/C70wCvf.png'

const cf_header = 'https://i.imgur.com/JEtBp5I.jpg'
const hookah_header = 'https://i.imgur.com/C5n6tk7.jpg'
const rkp_header = 'https://i.imgur.com/AyPwVBr.jpg'
const virtonex_header = 'https://i.imgur.com/BhY12m3.jpg'
const yourmeal_header = 'https://i.imgur.com/3wnOViA.jpg'
const signax_header = 'https://i.imgur.com/mUYdsaE.jpg'
const octripus_header = 'https://i.imgur.com/kOdi5Iu.jpeg';

export const ProjectData: ProjectInfo[] = [
  {
    id: 'octripus',
    year: 2024,
    isArchived: true,
    isOnHome: true,

    engName: 'Octripus',
    descriptionHomeRUS: 'Платформа в формате социальной сети для путешественников, цифровых кочевников и блогеров, где можно создавать и монетизировать путеводители.',
    descriptionHomeENG: 'A social networking platform designed for travelers, digital nomads, and bloggers, where users can create and monetize travel guides.',
    descriptionArchiveRUS: 'Платформа в формате социальной сети для путешественников, цифровых кочевников и блогеров, где можно создавать и монетизировать путеводители.',
    descriptionArchiveENG: 'A social networking platform designed for travelers, digital nomads, and bloggers, where users can create and monetize travel guides.',

    useExternalLink: true,
    externalLink: 'https://octripus.com/',

    platforms: ['Web, Android, iOS'],
    works: ['Product design', 'UI/UX'],

    previewImage: octripus_preview,
    backgroundColorLight: '#CFF1B9',
    backgroundColorOnHoverLight: '#87DC50',
    backgroundColorDark: '#CFF1B9',
    backgroundColorOnHoverDark: '#87DC50',

    headerImage: octripus_header,
    shortDescriptionEng: 'Octripus is a platform designed for travelers and travel experts, combining the features of a social network and a content creation and monetization platform. Users can create personalized guides, share them openly or with restricted access, and sell them via subscriptions. The project targets content creators who want to share their experiences of unique locations worldwide.',
    shortDescriptionRus: 'Octripus — это сервис для путешественников и тревел-экспертов, сочетающий в себе функции социальной сети и платформы для создания и монетизации контента. Здесь пользователи могут создавать авторские гайды, делиться ими со всеми пользователями или ограниченно, а также продавать их по подписке. Проект ориентирован на авторов контента, стремящихся делиться опытом посещения уникальных мест в разных странах.',
    toolTags: [ToolTagType.FIGMA, ToolTagType.PHOTOSHOP, ToolTagType.ADOBE_ILLUSTRATOR],
    linkButtons: [
      { type: LinkButtonType.WEBSITE, link: 'https://octripus.com/'}
    ],
    page: <OctripusPage/>,

    stats: [
      { num: '9', descriptionRUS: 'человек в команде стартапа', descriptionENG: 'people in the startup team' },
      { num: '160', descriptionRUS: 'дней заняли исследования<br>и поэтапный дизайн-процесс', descriptionENG: 'days spent on research<br>and phased design process' },
      { num: '250+', descriptionRUS: 'уникальных экранов для десктопа и смартфонов', descriptionENG: 'unique screens for desktop<br>and smartphones' },
      { num: '100+', descriptionRUS: 'UI-компонентов<br>в дизайн-системе', descriptionENG: 'UI components<br>in the design system' },
    ]
  },
  {
    id: 'yourmeal',
    year: 2024,
    isArchived: true,
    isOnHome: true,

    engName: 'YourMeal',
    descriptionHomeRUS: 'Амбициозный фудтех-стартап, выводящий взаимодействие ресторана и\u00A0гостя на\u00A0новый уровень',
    descriptionHomeENG: 'An\u00A0ambitious foodtech startup that takes restaurant-guest interaction to\u00A0the next level',
    descriptionArchiveRUS: 'Амбициозный фудтех-стартап, выводящий взаимодействие ресторана и\u00A0гостя на\u00A0новый уровень.',
    descriptionArchiveENG: 'An\u00A0ambitious foodtech startup that takes the interaction between restaurant and\u00A0guest to\u00A0a\u00A0new level.',
    platforms: ['Web', 'iOS', 'Android'],
    works: ['Product Design', 'UI/UX'],

    previewImage: yourmeal_preview,
    backgroundColorLight: '#D8FFE9',
    backgroundColorOnHoverLight: '#00DF5F',
    backgroundColorDark: '#D8FFE9',
    backgroundColorOnHoverDark: '#00DF5F',

    headerImage: yourmeal_header,
    shortDescriptionEng: '<p>Yourmeal is a food tech service that fully digitalizes the guest-restaurant interaction. You can scan the QR on your table and open a functional online menu with detailed characteristics of each dish for those who do sports or follow special diets. Having created an order, you receive a confirmation from the waiter and pay the bill through the service.</p>A website, a cross-platform mobile application and a CMS module are being developed as part of the project.',
    shortDescriptionRus: '<p>Yourmeal — фудтех-сервис, полностью цифровизирующий взаимодействие гостя с рестораном. Вы можете сесть за столик, отсканировать QR и открыть функциональное онлайн-меню с подробными характеристиками каждого блюда для тех, кто занимается спортом или придерживается особых диет. Создав заказ, вы получаете подтверждение от официанта и оплачиваете счёт через сервис.</p>В рамках работы над проектом разрабатывается веб-сайт, кроссплатформенное мобильное приложение и CMS-модуль.',
    toolTags: [ToolTagType.FIGMA, ToolTagType.PHOTOSHOP, ToolTagType.ADOBE_ILLUSTRATOR],
    linkButtons: [
      { type: LinkButtonType.WEBSITE, link: 'https://yourmeal.app'}
    ],
    page: <YourmealPage/>,

    stats: [
      { num: '120+', descriptionRUS: 'уникальных экранов для десктопа и смартфонов', descriptionENG: 'unique screens for desktop and smartphones' },
      { num: '2', descriptionRUS: 'модуля в рамках проекта\u00A0— для клиентов и сервиса', descriptionENG: 'modules within the project\u00A0— for clients and service' },
      { num: '140', descriptionRUS: 'дней от ТЗ до реализации проекта', descriptionENG: 'days from TOR to project realization' },
    ]
  },
  {
    id: 'signax',
    year: 2021,
    isArchived: true,
    isOnHome: true,

    engName: 'SIGNAX',
    descriptionHomeRUS: 'Комплекс программных решений и\u00A0сервисов для строительных компаний, BIM-специалистов и\u00A0инженеров',
    descriptionHomeENG: 'A\u00A0set of\u00A0software solutions for construction companies, BIM-managers and\u00A0developers',
    descriptionArchiveRUS: 'Продуктовый дизайн для компании-разработчика комплекса программных решений в\u00A0области строительства.',
    descriptionArchiveENG: 'A\u00A0set of\u00A0software solutions for construction companies, BIM-managers and\u00A0developers.',

    platforms: ['Web', 'iOS', 'Android', 'Windows', 'MacOS', 'Physical', 'Socials'],
    works: ['Product design', 'UI/UX', 'graphic design', 'print design'],

    previewImage: signax_preview,
    backgroundColorLight: '#AFE6FF',
    backgroundColorOnHoverLight: '#3CC2FF',
    backgroundColorDark: '#AFE6FF',
    backgroundColorOnHoverDark: '#3CC2FF',

    headerImage: signax_header,
    shortDescriptionEng: '<p>SIGNAX is a set of construction management software solutions consisting of various modules for document management, remote construction control, analytics and reporting. Working separately or as part of a single system, they provide digitalization of the construction process, making construction modern, efficient and safe.</p>The services are realized in the format of web, as well as applications for Android, iOS, Windows and macOS.',
    shortDescriptionRus: '<p>SIGNAX — это комплекс программных решений для управления строительством, состоящий из различных модулей для документооборота, удалённого строительного контроля, аналитики и отчётности. Работая как отдельно, так и в составе одной системы, они обеспечивают цифровизацию строительного процесса, делая стройку современной, эффективной и безопасной.</p>Сервисы реализованы в формате веб, а также приложений для Android, iOS, Windows и macOS. ',
    toolTags: [ToolTagType.FIGMA, ToolTagType.PHOTOSHOP, ToolTagType.ADOBE_ILLUSTRATOR, ToolTagType.SPLINE],
    linkButtons: [
      { type: LinkButtonType.WEBSITE, link: 'https://signax.io' }
    ],
    page: <SignaxPage/>,

    stats: [
      { num: '4', descriptionRUS: 'отдельных продукта<br>в рамках экосистемы', descriptionENG: 'separate products within the ecosystem' },
      { num: '700+', descriptionRUS: 'уникальных экранов для<br>десктопа и смартфонов', descriptionENG: 'unique screens for desktop and smartphones' },
      { num: '380+', descriptionRUS: 'UI-компонентов<br>в дизайн-системе', descriptionENG: 'UI components<br>in the design system' },
      { num: '15+', descriptionRUS: 'компаний используют<br>разработанное решение', descriptionENG: 'companies use<br>the developed solution' },
    ]
  },
  {
    id: 'centrofinans',
    year: 2021,
    isArchived: true,
    isOnHome: true,

    engName: 'Centrofinans',
    descriptionHomeRUS: 'Мобильное приложение одной из\u00A0крупнейших микрофинансовых организаций',
    descriptionHomeENG: 'Mobile app of a\u00A0large microfinance organization',
    descriptionArchiveRUS: 'Мобильное приложение одной из\u00A0крупнейших микрофинансовых организаций.',
    descriptionArchiveENG: 'Mobile app of a\u00A0large microfinance organization.',

    platforms: ['iOS', 'Android'],
    works: ['UI/UX'],

    previewImage: cf_preview,
    backgroundColorLight: '#FFDAD1',
    backgroundColorOnHoverLight: '#FF461B',
    backgroundColorDark: '#FFDAD1',
    backgroundColorOnHoverDark: '#FF461B',

    headerImage: cf_header,
    shortDescriptionRus: 'Мобильное приложение для\u00A0проекта «Деньги под\u00A0авто» микрофинансовой компании Центрофинанс. С\u00A0помощью приложения можно удалённо, без\u00A0явки в\u00A0офис, получить кредитные средства на\u00A0банковскую карту под\u00A0залог автомобиля.',
    shortDescriptionEng: 'The mobile app was developed as\u00A0a\u00A0part of\u00A0Centrofinans company’s project. The purpose of the development is to\u00A0allow the user to\u00A0get a\u00A0car loan completely remotely, using their documents and the mobile app on\u00A0their smartphone. Subsequently, users can pay off the loan through the application.',
    toolTags: [ToolTagType.FIGMA],
    linkButtons: [
      { type: LinkButtonType.BEHANCE, link: 'https://www.behance.net/gallery/192305611/Centrofinans-app'},
      { type: LinkButtonType.GOOGLE_PLAY, link: 'https://play.google.com/store/apps/details?id=ru.centrofinans.pts'}
    ],
    page: <CentrofinansPage/>
  },
  {
    id: 'virtonex',
    year: 2023,
    isArchived: true,
    isOnHome: true,

    engName: 'Virtonex',
    descriptionHomeRUS: 'Приложение для создания и\u00A0обработки метавселенных',
    descriptionHomeENG: 'App for creating and\u00A0working with metaverses',
    descriptionArchiveRUS: 'Веб-сервис и\u00A0мобильное приложение для создания и\u00A0работы с\u00A0метавселенными.',
    descriptionArchiveENG: 'Web service and mobile app for creating and working with metaverses.',

    platforms: ['Web', 'iOS', 'Android', 'Windows', 'MacOS'],
    works: ['UI/UX'],

    previewImage: virtonex_preview,
    headerImage: virtonex_header,
    backgroundColorLight: '#D4DBFF',
    backgroundColorOnHoverLight: '#2649FF',
    backgroundColorDark: '#D4DBFF',
    backgroundColorOnHoverDark: '#2649FF',

    shortDescriptionRus: '<p>Для компании-разработчика Web3-решений был создан дизайн конструктора пространств в формате метавселенной.</p>Пространства могут быть как долгосрочными (например, виртуальный музей), так и временными (выставка или форум). Кроме того, можно создавать карты просто для развлечения или общения с друзьями. Основная аудитория — корпорации или инфлюенсеры. Они используют решение для проведения виртуальных онлайн-мероприятий.',
    shortDescriptionEng: '<p>The design of a metaverse space builder was created for a Web3 solutions developer company.</p>Spaces can be both long-term (e.g. virtual museum) and temporary (exhibition or forum). It is also possible to create maps just for fun or socializing with friends. The main audience is corporations or influencers. They use the solution for virtual online events.',
    toolTags: [ToolTagType.FIGMA, ToolTagType.PHOTOSHOP],
    page: <VirtonexPage/>
  },
  {
    id: 'royal-king-properties',
    year: 2023,
    isArchived: true,
    isOnHome: true,

    engName: 'Royal King properties',
    descriptionHomeRUS: 'Сайт агенства элитной недвижимости в\u00A0Дубае',
    descriptionHomeENG: 'Site for a\u00A0luxury real estate agency in\u00A0Dubai, UAE',
    descriptionArchiveRUS: 'Сайт агенства элитной недвижимости в\u00A0Дубае.',
    descriptionArchiveENG: 'Website for a\u00A0Luxury real estate agency in\u00A0Dubai, UAE.',

    platforms: ['Web', 'Socials'],
    works: ['Product Design', 'UI/UX'],

    previewImage: rkp_preview,
    backgroundColorLight: '#F5E2C8',
    backgroundColorOnHoverLight: '#FFDE6A',
    backgroundColorDark: '#F5E2C8',
    backgroundColorOnHoverDark: '#FFDE6A',

    headerImage: rkp_header,
    shortDescriptionEng: 'Website of\u00A0luxury real estate agency in\u00A0Dubai, made in\u00A0Swiss style\u00A0— with right angles, «square» letters and\u00A0stylish animation.',
    shortDescriptionRus: 'Сайт агенства элитной недвижимости в Дубае, выполненный в\u00A0швейцарском стиле\u00A0— с прямыми углами, «квадратными» буквами и\u00A0стильной анимацией.',
    toolTags: [ToolTagType.FIGMA, ToolTagType.PHOTOSHOP, ToolTagType.ADOBE_ILLUSTRATOR],
    linkButtons: [
      { type: LinkButtonType.BEHANCE, link: 'https://www.behance.net/gallery/192711445/Royal-King-Properties'},
      { type: LinkButtonType.WEBSITE, link: 'https://juliakuranova.github.io/RoyalKingProperties/'}
    ],
    page: <RKP_Page/>
  },
  {
    id: 'hookah-pt',
    year: 2023,
    isArchived: true,
    isOnHome: true,

    engName: 'Hookah.pt',
    descriptionHomeRUS: 'Первый онлайн-магазин кальянных товаров в\u00A0Португалии',
    descriptionHomeENG: 'The first online hookah store in\u00A0Portugal',
    descriptionArchiveRUS: 'Первый онлайн-магазин кальянной продукции в\u00A0Португалии.',
    descriptionArchiveENG: 'The first online hookah store in\u00A0Portugal.',

    platforms: ['Web', 'Physical'],
    works: ['Product Design', 'UI/UX'],

    previewImage: hookah_preview,
    backgroundColorLight: '#F0F0F0',
    backgroundColorOnHoverLight: '#373739',
    backgroundColorDark: '#F0F0F0',
    backgroundColorOnHoverDark: '#373739',

    headerImage: hookah_header,
    shortDescriptionRus: 'Hookah.pt – первый онлайн-магазин кальянных товаров в Португалии. Для проекта был создан харизматичный фирменный стиль, разработан онлайн-магазин и оформление физических носителей.',
    shortDescriptionEng: 'Hookah.pt is the first online hookah store in Portugal. A charismatic corporate identity was created for the project and the online store as well as the design of physical media were developed.',
    toolTags: [ToolTagType.FIGMA, ToolTagType.PHOTOSHOP, ToolTagType.ADOBE_ILLUSTRATOR],
    linkButtons: [
      { type: LinkButtonType.WEBSITE, link: 'https://zaozerskikh.github.io/hookah'},
    ],
    page: <HPT_Page />
  },


  // archive
  {
    id: 'audioguide-paris',
    year: 2023,
    isArchived: true,
    isOnHome: false,
    useExternalLink: true,
    externalLink: 'https://apps.apple.com/us/app/audio-guide-paris/id6471984315',

    engName: 'Audio Guide Paris',
    descriptionArchiveRUS: 'Мобильное приложение – гид по\u00A0туристическим маршрутам Парижа.',
    descriptionArchiveENG: 'Mobile application – a\u00A0guide to\u00A0tourist routes in\u00A0Paris.',
    descriptionHomeRUS: 'Мобильное приложение – гид по\u00A0туристическим маршрутам Парижа.',
    descriptionHomeENG: 'Guide to\u00A0Paris tourist destinations mobile app.',

    platforms: ['iOS', 'Android'],
    works: ['UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'zaozerskikh-ru',
    year: 2023,
    isArchived: true,
    isOnHome: false,
    useExternalLink: true,
    externalLink: 'https://zaozerskikh.ru/',

    engName: 'Zaozerskikh.ru',
    descriptionHomeRUS: 'Сайт-портфолио для фронтенд-разработчика.',
    descriptionHomeENG: 'Portfolio website for\u00A0front-end developer.',
    descriptionArchiveRUS: 'Сайт-портфолио для фронтенд-разработчика.',
    descriptionArchiveENG: 'A\u00A0portfolio website for a\u00A0frontend developer.',

    platforms: ['Web'],
    works: ['Brand', 'UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'teleportation',
    year: 2023,
    isArchived: true,
    isOnHome: false,

    engName: 'Teleportation',
    descriptionHomeRUS: 'Приложение для создания AR с\u00A0помощью LIDAR-сканера.',
    descriptionHomeENG: 'Application for creating AR using a\u00A0LIDAR scanner.',
    descriptionArchiveRUS: 'Приложение для создания AR с\u00A0помощью LIDAR-сканера.',
    descriptionArchiveENG: 'Mobile app for creating AR using a\u00A0LIDAR scanner.',

    platforms: ['iOS', 'Android'],
    works: ['UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'baibol',
    year: 2022,
    isArchived: true,
    isOnHome: false,

    engName: 'Baibol',
    descriptionHomeRUS: 'Мобильное приложение микрофинансовой организации.',
    descriptionHomeENG: 'Mobile app for a\u00A0microfinance organization.',
    descriptionArchiveRUS: 'Мобильное приложение микрофинансовой организации.',
    descriptionArchiveENG: 'Mobile app for a\u00A0microfinance organization.',

    platforms: ['Android'],
    works: ['UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'share-space',
    year: 2021,
    isArchived: true,
    isOnHome: false,

    engName: 'ShareSpace',
    descriptionHomeRUS: 'Маркетинговые материалы и\u00A0графика для стартапа в\u00A0сфере ментального здоровья.',
    descriptionHomeENG: 'Marketing materials and graphics for a\u00A0mental health startup.',
    descriptionArchiveRUS: 'Маркетинговые материалы и\u00A0графика для стартапа в\u00A0сфере ментального здоровья.',
    descriptionArchiveENG: 'Marketing materials and graphics for a\u00A0mental health startup.',

    platforms: ['Google Play', 'App Store', 'physical', 'socials'],
    works: ['Graphic design'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'dreamshifter-store',
    year: 2021,
    isArchived: true,
    isOnHome: false,

    engName: 'Dreamshifter.store',
    descriptionHomeRUS: 'Магазин постеров для стрит/урбекс-фотографа.',
    descriptionHomeENG: 'Online poster store for the street/urban photographer.',
    descriptionArchiveRUS: 'Магазин постеров для стрит/урбекс-фотографа.',
    descriptionArchiveENG: 'Online poster store for the street/urban photographer.',

    platforms: ['Web'],
    works: ['UI/UX', 'Tilda layout'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'retail-dashboard',
    year: 2021,
    isArchived: true,
    isOnHome: false,

    engName: 'Retail dashboard',
    descriptionHomeRUS: 'Дашборд для управления торговыми точками. Работа в\u00A0рамках защиты диссертации на\u00A0степень магистра.',
    descriptionHomeENG: 'Retail management dashboard. Task included in\u00A0the framework of\u00A0master’s dissertation.',
    descriptionArchiveRUS: 'Дашборд для управления торговыми точками. Работа в\u00A0рамках защиты диссертации на\u00A0степень магистра.',
    descriptionArchiveENG: 'Retail management dashboard. Task included in\u00A0the framework of\u00A0master’s dissertation.',

    platforms: ['Web'],
    works: ['UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'instats',
    year: 2019,
    isArchived: true,
    isOnHome: false,

    engName: 'Instats',
    descriptionHomeRUS: 'Приложение для сбора и\u00A0анализа статистики профиля в\u00A0социальной сети. Проект выполнен в\u00A0рамках защиты дипломной работы.',
    descriptionHomeENG: 'Mobile app for collecting and analyzing profile statistics in\u00A0a\u00A0social network. Project completed as a\u00A0part of the bachelor’s thesis.',
    descriptionArchiveRUS: 'Приложение для сбора и\u00A0анализа статистики профиля в\u00A0социальной сети. Проект выполнен в\u00A0рамках защиты дипломной работы.',
    descriptionArchiveENG: 'Mobile app for\u00A0collecting and analyzing profile statistics in a\u00A0social network. Project completed as a\u00A0part of the bachelor’s thesis.',

    platforms: ['iOS'],
    works: ['UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'tinder-for-travellers',
    year: 2019,
    isArchived: true,
    isOnHome: false,

    engName: 'Tinder for traveling',
    rusName: 'Tinder для путешествий',
    descriptionHomeRUS: 'Приложение в\u00A0рамках кейса от\u00A0Aviasales, финалист VK Hackathon 2019.',
    descriptionHomeENG: 'Case study app from Aviasales. Finalist of\u00A0VK Hackathon 2019.',
    descriptionArchiveRUS: 'Приложение в\u00A0рамках кейса от Aviasales, финалист VK Hackathon 2019.',
    descriptionArchiveENG: 'Case study app from Aviasales. Finalist of\u00A0VK Hackathon 2019.',

    platforms: ['iOS', 'Web'],
    works: ['Graphic design', 'UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'hit-the-spot',
    year: 2018,
    isArchived: true,
    isOnHome: false,

    engName: 'Hit the spot',
    descriptionHomeRUS: 'Приложение для подбора недвижимости по\u00A0экологическим параметрам местности, использующее API и\u00A0карты компании HERE. Работа взяла гран-при Map_IT Hackathon в\u00A0Кракове, Польша, 2018.',
    descriptionHomeENG: 'Mobile app for searching real estate according to\u00A0environmental parameters using HERE\'s API and maps. The grand prize of Map_IT Hackathon in Krakow, Poland, 2018.',
    descriptionArchiveRUS: 'Приложение для подбора недвижимости по\u00A0экологическим параметрам местности, использующее API и\u00A0карты компании HERE. Работа взяла гран-при Map_IT Hackathon в\u00A0Кракове, Польша, 2018.',
    descriptionArchiveENG: 'Mobile app for\u00A0searching real estate according to\u00A0environmental parameters using HERE\'s API and maps. The grand prize of Map_IT Hackathon in Krakow, Poland, 2018.',

    platforms: ['iOS', 'Web'],
    works: ['Graphic design', 'UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'wheres-atm',
    year: 2018,
    isArchived: true,
    isOnHome: false,

    engName: 'Where\'s the ATM app',
    rusName: 'Где банкомат',
    descriptionHomeRUS: 'Приложение для быстрого поиска банкомата по конкретному запросу, работающее с\u00A0навигацией, голосовым помощником и основанное на\u00A0API mastercard. Финалист VK hackathon – Art of coding 2018.',
    descriptionHomeENG: 'Mobile app for fast ATM search by specific request, working with navigation, voice assistant and based on\u00A0mastercard API. Finalist of VK hackathon – Art of coding 2018.',
    descriptionArchiveRUS: 'Приложение для быстрого поиска банкомата по конкретному запросу, работающее с\u00A0навигацией, голосовым помощником и основанное на\u00A0API mastercard. Финалист VK hackathon – Art of coding 2018.',
    descriptionArchiveENG: 'Mobile app for fast ATM search by specific request, working with navigation, voice assistant and based on\u00A0mastercard API. Finalist of VK hackathon – Art of coding 2018.',

    platforms: ['iOS', 'Web'],
    works: ['Graphic design', 'UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'stankin-brandbook',
    year: 2018,
    isArchived: true,
    isOnHome: false,

    engName: 'MSUT STANKIN brandbook',
    descriptionHomeRUS: 'Брендбук и\u00A0графические материалы для\u00A0университета.',
    descriptionHomeENG: 'Brandbook and\u00A0graphic materials for the university.',
    descriptionArchiveRUS: 'Брендбук и\u00A0графические материалы для\u00A0университета.',
    descriptionArchiveENG: 'Brandbook and\u00A0graphic materials for the university.',

    platforms: ['iOS', 'Web'],
    works: ['Graphic design', 'UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
  {
    id: 'room-scanner',
    year: 2024,
    isArchived: true,
    isOnHome: false,

    engName: 'Room Scanner',
    descriptionHomeRUS: 'Приложение для создания 3D-модели пространства, отсканированного камерой мобильного телефона.',
    descriptionHomeENG: 'An application for creating a 3D model of a space scanned by a smartphone camera.',
    descriptionArchiveRUS: 'Приложение для создания 3D-модели пространства, отсканированного камерой мобильного телефона.',
    descriptionArchiveENG: 'An application for creating a 3D model of a space scanned by a smartphone camera.',

    platforms: ['Android'],
    works: ['Product design', 'UI/UX'],

    previewImage: '',
    backgroundColorLight: '',
    backgroundColorOnHoverLight: '',
    backgroundColorDark: '',
    backgroundColorOnHoverDark: ''
  },
]
